'use client'
import { cn } from '@/utils/cn'
import { cva, type VariantProps } from 'class-variance-authority'
import {
  Ban,
  Check,
  ChevronDown,
  CircleAlert,
  TriangleAlert,
  X,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Button } from './Button'

export type MessageVariants = VariantProps<typeof messageVariants>

const messageVariants = cva(
  'relative rounded border border-l-4 border-solid  w-fullr  pl-11 pr-4 py-4 ${className}',
  {
    variants: {
      variant: {
        success: 'border-primary-6 bg-primary-6 bg-opacity-25',
        notice: 'border-primary-8 bg-primary-8 bg-opacity-25',
        warning: 'border-primary-7 bg-primary-7 bg-opacity-25',
        error: 'border-primary-5 bg-primary-5 bg-opacity-25',
      },
    },
    defaultVariants: {
      variant: 'success',
    },
  },
)

type MessageProps = {
  title?: string
  content: string | React.ReactNode
  className?: string
  scrollIntoView?: boolean
  collapsable?: never
  onClose?: never
  startCollapsed?: never
} & MessageVariants

interface ClosableMessageProps extends Omit<MessageProps, 'onClose'> {
  onClose: () => void
}

interface CollapsableMessageProps
  extends Omit<MessageProps, 'collapsable' | 'startCollapsed'> {
  collapsable: boolean
  startCollapsed?: boolean
}

const defaultIcons = {
  error: Ban,
  notice: CircleAlert,
  success: Check,
  warning: TriangleAlert,
}

const Message = ({
  variant = 'success',
  onClose,
  collapsable,
  title,
  content,
  className,
  scrollIntoView,
  startCollapsed,
}: MessageProps | ClosableMessageProps | CollapsableMessageProps) => {
  const Icon = variant ? defaultIcons[variant] : null
  const messageRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState<boolean>(!startCollapsed) // Default to an empty string for a closed state
  useEffect(() => {
    if (scrollIntoView) {
      messageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return (
    <div
      ref={messageRef}
      className={cn(messageVariants({ variant }), className)}
    >
      <span
        className={`absolute left-3 top-4 flex h-5 w-5 items-center text-black`}
      >
        {Icon && <Icon width={18} height={18} />}
      </span>
      <div className={`mb-1 pr-5 text-base font-bold leading-5 text-black`}>
        {title ?? <span className="capitalize">variant</span>}
      </div>
      {(onClose || collapsable) && (
        <span className="absolute right-3 top-4 flex h-5 w-5 items-center justify-center text-black">
          {onClose && (
            <Button variant="ghost" className={`h-6 w-6 p-0`} onClick={onClose}>
              <X width={18} height={18} />
            </Button>
          )}
          {collapsable && (
            <Button
              id="service-message-collapser"
              variant="ghost"
              className={cn(
                'h-6 w-6 p-0 transition-all [&[data-state=open]>svg]:rotate-180',
              )}
              data-state={expanded ? 'open' : 'closed'}
              onClick={() => setExpanded(!expanded)}
            >
              <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
            </Button>
          )}
        </span>
      )}
      {expanded && content}
    </div>
  )
}

export default Message
