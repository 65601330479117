import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Container/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ErrorCatcher/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageDropdown","LanguageDropdownItem"] */ "/vercel/path0/src/components/Header/LanguageDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/MockHeader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ServiceMessage/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/URLCleaner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "/vercel/path0/src/contexts/GlobalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/LocaleProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/base.css");
