import { error } from '@hi3g-access/client-logger'
import { isServer } from './isServer'
import log from './log'

export type ErrorCode = {
  ok: false
  errorCode: number
  transactionId: string
}

interface ErrorMap {
  [key: number]: number
}

const errors: ErrorMap = {
  4000: 40000,
  4001: 40001, // INVALID_CREDENTIALS both email and otp
  4004: 40002, // User or customer not found
  4005: 40005, // Username already exists
  4006: 40006, // Invalid email
  4280: 42800, // MultiAdmin needs to select a customer before being redirected
  4031: 40310, // User is trying to login via a client which does not support his/her customer type
  5001: 50001,
  5000: 50000,
}

const handleError = (
  backendError: number,
  transactionId: string,
): ErrorCode => {
  log(
    `[ErrorHandler (Server: ${isServer()})]: Backend error code: ${backendError}`,
  )
  error({ message: `[ErrorHandler]: Backend error code: ${backendError}` })
  return {
    ok: false,
    errorCode: errors[backendError] ?? 50000,
    transactionId,
  }
}

export const enum ErrorType {
  UNKNOWN = 'UNKNOWN',
  CANCELED = 'CANCELED',
  WRONGOTP = 'WRONGOTP',
}

interface ClientErrorMap {
  [key: number]: ErrorType
}

export const clientError: ClientErrorMap = {
  5000: ErrorType.UNKNOWN,
  4001: ErrorType.CANCELED,
  40009: ErrorType.WRONGOTP,
}

export default handleError
