import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.components.OTPForm'

export default defineMessages({
  verifyCodeTitle: {
    defaultMessage: 'Verify otp code',
    id: `${messagesPath}.verifyCodeTitle`,
  },
  verifyCodeLabel: {
    defaultMessage: 'Code',
    id: `${messagesPath}.verifyCodeLabel`,
  },
  verifyCode: {
    defaultMessage: 'Verify',
    id: `${messagesPath}.verifyCode`,
  },
  verifyCodeExplainer: {
    defaultMessage:
      'The code is valid for three minutes, afterwards you need to order a new one.',
    id: `${messagesPath}.verifyCodeExplainer`,
  },
  back: {
    defaultMessage: 'Back',
    id: `${messagesPath}.back`,
  },
  incorrectCodeTitle: {
    defaultMessage: 'The code is incorrect',
    id: `${messagesPath}.incorrectCodeTitle`,
  },
  incorrectCodeMessage: {
    defaultMessage: 'The verification code you entered is not correct',
    id: `${messagesPath}.incorrectCodeMessage`,
  },
  genericErrorTitle: {
    defaultMessage: 'Error',
    id: `${messagesPath}.genericErrorTitle`,
  },
  genericErrorMessage: {
    defaultMessage: 'Something went wrong',
    id: `${messagesPath}.genericErrorMessage`,
  },
})
