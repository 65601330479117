import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.components.Container'

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: `${messagesPath}.cancel`,
  },
})
