import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.components.ErrorCatcher'

const errorMessages = defineMessages({
  genericErrorTitle: {
    defaultMessage: 'Error',
    id: `${messagesPath}.genericErrorTitle`,
  },
  genericErrorMessage: {
    defaultMessage: 'Something went wrong',
    id: `${messagesPath}.genericErrorMessage`,
  },
  emailTooLongError: {
    defaultMessage: 'Email address is too long',
    id: `${messagesPath}.emailTooLongError`,
  },
  passwordTooLong: {
    defaultMessage: 'Password is too long',
    id: `${messagesPath}.passwordTooLong`,
  },
  taxpayerIdTooLong: {
    defaultMessage: 'Taxpayer id is too long',
    id: `${messagesPath}.taxpayerIdTooLong`,
  },
  firstnameIsTooLong: {
    defaultMessage: 'First name is too long',
    id: `${messagesPath}.firstnameIsTooLong`,
  },
  lastnameIsTooLong: {
    defaultMessage: 'Last name is too long',
    id: `${messagesPath}.lastnameIsTooLong`,
  },
  userCanceled: {
    defaultMessage: 'Canceled by user.',
    id: `${messagesPath}.userCanceled`,
  },
})
export default errorMessages
export type ErrorKey = keyof typeof errorMessages
