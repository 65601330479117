import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.components.LanguageSelect'
const strings = {
  sv: {
    defaultMessage: 'Swedish',
    id: `${messagesPath}.sv`,
  },
  da: {
    defaultMessage: 'Danish',
    id: `${messagesPath}.da`,
  },
  en: {
    defaultMessage: 'English',
    id: `${messagesPath}.en`,
  },
  language: {
    defaultMessage: 'Language',
    id: `${messagesPath}.language`,
  },
}

export default defineMessages(strings)
