'use client'
import { useGlobalContext } from '@/contexts/GlobalContext'
import Link from 'next/link'

export const IntlToggle = () => {
  const context = useGlobalContext()
  return (
    <Link
      onClick={() => context.toggleIntlMode()}
      className={`no-underline hover:underline ${context.intlDemoMode ? 'font-bold' : ''}`}
      href="#"
    >
      Intl Demo
    </Link>
  )
}
