import * as React from 'react'
import { type ReactNode } from 'react'

import {
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  DropdownMenu as _DropdownMenu,
  DropdownMenuItem as _DropdownMenuItem,
} from '@/lib/shadcn/ui/dropdown-menu'

/*  DROPDOWN MENU */

export type DropdownMenuProps = {
  align?: 'start' | 'center' | 'end'
  children: ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  trigger: ReactNode
  triggerId?: string
}

export const DropdownMenu = ({
  align = 'center',
  children,
  side = 'bottom',
  trigger,
  triggerId,
}: DropdownMenuProps) => {
  return (
    <_DropdownMenu>
      <DropdownMenuTrigger className="select-none outline-none" id={triggerId}>
        {trigger}
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <DropdownMenuContent
          className="border border-muted bg-primary"
          side={side}
          sideOffset={6}
          align={align}
        >
          {children}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </_DropdownMenu>
  )
}

DropdownMenu.displayName = 'DropdownMenu'

/*  DROPDOWN MENU ITEM */

export type DropdownMenuItemProps = Pick<
  React.ComponentPropsWithoutRef<typeof _DropdownMenuItem>,
  'onClick' | 'disabled' | 'children' | 'id'
> & {
  ref?: React.RefObject<HTMLDivElement>
}

export const DropdownMenuItem = ({
  children,
  onClick,
  ref,
  ...props
}: DropdownMenuItemProps) => {
  return (
    <_DropdownMenuItem
      ref={ref}
      {...props}
      className="cursor-pointer p-2.5"
      onClick={e => {
        e.stopPropagation()
        onClick?.(e)
      }}
    >
      {children}
    </_DropdownMenuItem>
  )
}

DropdownMenuItem.displayName = 'DropdownMenuItem'
