'use client'
import { useClientCookie } from '@/app/hooks/useCookie/useClientCookie'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import { Button } from '@/components/Button'
import { cancelIFrame } from '@/utils/iFrameUtils'
import messages from './container.messages'

const Footer = () => {
  const { iFrame } = useClientCookie(false)
  const formatMessage = useFormatMessage()
  if (iFrame === 'true')
    return (
      <div className="fixed bottom-5 left-5 right-5 flex content-end items-center justify-end">
        <Button variant="ghost" type="button" onClick={cancelIFrame}>
          {formatMessage(messages.cancel)}
        </Button>
      </div>
    )
}

export default Footer
