import { defineMessages } from 'react-intl'

const messagesPath = 'customer-login.components.ServiceMessage'

const serviceMessages = defineMessages({
  serviceMessageTitle: {
    defaultMessage: 'Service Announcement',
    id: `${messagesPath}.serviceMessageTitle`,
  },
  serviceMessage: {
    defaultMessage: 'Service announcement message {messageLink}',
    id: `${messagesPath}.serviceMessage`,
  },
  serviceUrl: {
    defaultMessage: 'https://www.tre.se',
    id: `${messagesPath}.serviceUrl`,
  },

  serviceUrlText: {
    defaultMessage: 'Link',
    id: `${messagesPath}.serviceUrlText`,
  },
})
export default serviceMessages
