'use client'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import config from '@/config'
import Message from '../Message'
import messages from './service.messages'

const ServiceMessage = () => {
  const formatMessage = useFormatMessage()

  if (config.NEXT_PUBLIC_SERVICE_MESSAGE === 'true')
    return (
      <Message
        collapsable
        variant="warning"
        className="m-0 mb-4 text-sm"
        title={formatMessage(messages.serviceMessageTitle)}
        startCollapsed
        content={
          <div>
            <p className="text-justify">
              {formatMessage(messages.serviceMessage, {
                messageLink: (
                  <a
                    className="cursor-pointer underline hover:text-slate-600"
                    href={formatMessage(messages.serviceUrl)}
                  >
                    {formatMessage(messages.serviceUrlText)}
                  </a>
                ),
              })}
            </p>
          </div>
        }
      />
    )
}

export default ServiceMessage
