'use client'
import { SessionConstants } from '@/constants'
import isProduction from '@/utils/isProduction'
import { isServer } from '@/utils/isServer'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { IntlToggle } from './IntlToggle'

const MockHeader = () => {
  const router = useRouter()
  const showMockHeader =
    !isProduction() &&
    !isServer() &&
    sessionStorage.getItem(SessionConstants.Mock)

  if (!showMockHeader) return null

  const removeMockHeader = () => {
    sessionStorage.removeItem(SessionConstants.Mock)
    router.refresh()
  }
  return (
    <div className="absolute left-0 right-0 top-0 flex h-6 items-center justify-start border-b border-slate-800 bg-slate-400 px-3">
      <Link className="mr-4 pr-4 no-underline hover:underline" href="/mock">
        Mock
      </Link>
      <Link
        onClick={removeMockHeader}
        href="#"
        className="mr-4 pr-4 no-underline hover:underline"
      >
        Remove Mock
      </Link>
      <IntlToggle />
      <button className="ml-auto" onClick={removeMockHeader}>
        X
      </button>
    </div>
  )
}

export default MockHeader
