'use client'

import { cookieParams } from '@/constants'
import { isServer } from '@/utils/isServer'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

const URLCleaner = () => {
  const router = useRouter()
  let replace = false

  useEffect(() => {
    if (isServer()) return

    const url = new URL(window.location.href)
    Object.entries(cookieParams).forEach(([query]) => {
      if (url.searchParams.has(query)) {
        replace = true
      }
      url.searchParams.delete(query)
    })
    if (replace && !url.searchParams.has('error')) {
      router.replace(url.toString())
    }
  })

  return null
}

export default URLCleaner
